import { Box, Typography, Link } from '@mui/material';
import FairtreeLogo from '../../resources/assets/images/fairtree-logo.png';
import FairtreeLogoDark from '../../resources/assets/images/fairtree-logo-dark.png';
import { useColorMode } from '../../hooks/colorModeProvider';

const PoweredBy = () => {
  const { mode } = useColorMode();

  return (
    <Box display={'flex'}>
      <Link href="https://www.fairtree.com" target="_blank">
        <img
          src={mode ? FairtreeLogoDark : FairtreeLogo}
          alt="Fairtree Logo"
          style={{ width: '250px', height: 'auto' }}
        />
      </Link>
    </Box>
  );
};

export default PoweredBy;
