/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, List, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import Input from '../ui/forms/fields/input';

import TeamColorPicker from '../ui/teamControls/teamColorPicker';
import { useFormContext } from 'react-hook-form';
import { Fragment, useEffect, useState } from 'react';
import gameFormatService from '../../features/gameFormat/gameFormatService';
import { Delete } from '@mui/icons-material';

const CustomMultipleVs = ({ gameType }) => {
  const theme = useTheme();
  const [teamA, setTeamA] = useState({ players: [], color: 'green' });
  const [teamB, setTeamB] = useState({ players: [], color: 'red' });
  const [teams, setTeams] = useState({});
  const [teamLimit, setTeamLimit] = useState(0);

  const isMobile = theme.breakpoints.down('sm');

  //Register teamA and teamB
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();

  //REgister teamA field and teamB field
  useEffect(() => {
    register('teams');
  }, [register]);

  useEffect(() => {
    setTeamLimit(gameFormatService.getGameTypeLimit(gameType) / 2);
    setTeamA({ players: [], color: 'green' });
    setTeamB({ players: [], color: 'red' });
  }, [gameType]);

  useEffect(() => {
    setTeams({ teamA, teamB });
  }, [teamA, teamB]);

  useEffect(() => {
    setValue('teams', teams);
  }, [teams, setValue]);

  const watchTeamAColor = watch('teamAColor');
  const watchTeamBColor = watch('teamBColor');

  useEffect(() => {
    setTeamA({ ...teamA, color: watchTeamAColor });
  }, [watchTeamAColor]);

  useEffect(() => {
    setTeamB({ ...teamB, color: watchTeamBColor });
  }, [watchTeamBColor]);

  const addPlayer = (event, team) => {
    if (event.target.value === '') return;
    if (event.key !== 'Enter') return;
    const player = event.target.value;
    if (team === 'A') {
      if (teamA.players.length >= teamLimit) return;
      setTeamA({ ...teamA, players: [...teamA.players, player] });
    } else {
      if (teamB.players.length >= teamLimit) return;
      setTeamB({ ...teamB, players: [...teamB.players, player] });
    }

    event.target.value = '';
  };

  const removePlayer = (player, team) => {
    if (team === 'A') {
      const newPlayersA = teamA.players.filter((p) => p !== player);
      setTeamA({ ...teamA, players: newPlayersA });
    } else {
      const newPlayersB = teamB.players.filter((p) => p !== player);
      setTeamB({ ...teamB, players: newPlayersB });
    }
  };

  return (
    <Fragment>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Players Select
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent={'space-evenly'} gap={1}>
        <Box display="flex" flexDirection={'column'}>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1}>
            <Input
              name={'playerA'}
              label="Player A Name"
              helperText={'Enter name and press Enter'}
              onKeyUp={(event) => addPlayer(event, 'A')}
            />
            <Box sx={{ alignSelf: 'flex-start' }}>
              <TeamColorPicker name={'teamAColor'} defaultValue={'green'} />
            </Box>
          </Box>

          <List>
            {teamA.players.map((player, index) => (
              <MenuItem key={index} value={player}>
                <Stack direction="row" gap={1} alignItems={'center'}>
                  {player}
                  <IconButton size="small" onClick={() => removePlayer(player, 'A')}>
                    <Delete color="error" />
                  </IconButton>
                </Stack>
              </MenuItem>
            ))}
          </List>
        </Box>
        {!isMobile && (
          <Typography
            variant="h6"
            color="primary"
            fontWeight="bold"
            sx={{ mx: 2, alignSelf: 'center' }}>
            VS
          </Typography>
        )}
        <Box display="flex" flexDirection={'column'}>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1}>
            <Input
              name={'playerB'}
              label="Player B Name"
              helperText={'Enter name and press Enter'}
              onKeyUp={(event) => addPlayer(event, 'B')}
            />
            <Box sx={{ alignSelf: 'flex-start' }}>
              <TeamColorPicker name={'teamBColor'} defaultValue={'red'} />
            </Box>
          </Box>
          <List>
            {teamB.players.map((player, index) => (
              <MenuItem key={index} value={player}>
                <Stack direction="row" gap={1} alignItems={'center'}>
                  {player}
                  <IconButton size="small" onClick={() => removePlayer(player, 'B')}>
                    <Delete color="error" />
                  </IconButton>
                </Stack>
              </MenuItem>
            ))}
          </List>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CustomMultipleVs;
