import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, MenuItem, Paper, Stack, Typography, useTheme } from '@mui/material';

import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getAllGameFormats } from '../../features/gameFormat/gameFormatSlice';
import Select from '../ui/forms/fields/select';
import PrimaryButton from '../ui/buttons/primaryButton';
import GameFormatDescription from '../gameFormats/description';
import gameFormatService from '../../features/gameFormat/gameFormatService';
import CustomMultipleVs from './customMultipleVs';
import { createGame } from '../../features/game/gameSlice';
import SinglesPlayerDisplay from '../ui/playerDisplay/singles';
import MultiplePlayerDisplay from '../ui/playerDisplay/multiple';

const schema = yup.object().shape({
  gameFormat: yup.string().required('Game format is required'),
  gameType: yup.number().required('Game type is required'),
  teams: yup.mixed(),
  teamAColor: yup.string(),
  teamBColor: yup.string()
});

const NewGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const gameFormats = useSelector((state) => state.gameFormat.gameFormats);
  const loadingGameFormats = useSelector((state) => state.gameFormat.loading);
  const creatingGame = useSelector((state) => state.game.creatingGame);
  const [selectedGameFormat, setSelectedGameFormat] = useState(null);

  const isMobile = theme.breakpoints.down('sm');

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const errors = methods.formState.errors;

  useEffect(() => {
    dispatch(getAllGameFormats());
  }, [dispatch]);

  const watchGameFormat = methods.watch('gameFormat');
  const watchGameType = methods.watch('gameType');

  useEffect(() => {
    if (watchGameFormat) {
      setSelectedGameFormat(gameFormats.find((gameFormat) => gameFormat.id === watchGameFormat));
    }
  }, [watchGameFormat, gameFormats]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const validationCheck = (data) => {
    const teams = data.teams;
    const gamePlayerLimit = gameFormatService.getGameTypeLimit(data.gameType) / 2;

    const isTeamAEmpty = teams.teamA.players.length === 0;
    const isTeamBEmpty = teams.teamB.players.length === 0;
    const isTeamAValid = teams.teamA.players.length === gamePlayerLimit;
    const isTeamBValid = teams.teamB.players.length === gamePlayerLimit;

    if (isTeamAEmpty || isTeamBEmpty) {
      if (isTeamAEmpty) {
        methods.setError('teams', {
          type: 'manual',
          message: 'Team A must have players'
        });
      } else {
        methods.setError('teams', {
          type: 'manual',
          message: 'Teams B must have players'
        });
      }

      return false;
    }

    if (!isTeamAValid || !isTeamBValid) {
      if (!isTeamAValid) {
        methods.setError('teams', {
          type: 'manual',
          message: `Team A has only ${teams.teamA.players.length} out of ${gamePlayerLimit} players. Each team must have ${gamePlayerLimit} players`
        });
      } else {
        methods.setError('teams', {
          type: 'manual',
          message: `Team B has only ${teams.teamB.players.length} out of ${gamePlayerLimit} players. Each team must have ${gamePlayerLimit} players`
        });
      }
      return false;
    }
    return true;
  };

  const handleSaveForLater = (data) => {
    delete data.teamAColor;
    delete data.teamBColor;

    if (validationCheck(data)) {
      dispatch(createGame(data))
        .unwrap()
        .then((response) => {
          navigate('/dashboard');
        });
    }
  };

  return (
    <>
      <Typography variant="h5" mb={1}>
        Start New Game
      </Typography>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={'space-evenly'}
        gap={1}>
        <Box display={'flex'} flexDirection={'column'}>
          <Paper sx={{ p: 2 }}>
            {loadingGameFormats ? (
              <CircularProgress />
            ) : (
              <FormProvider {...methods}>
                <Select name="gameFormat" label="Game Format" error={errors.gameFormat}>
                  {gameFormats.map((gameFormat) => (
                    <MenuItem key={gameFormat.id} value={gameFormat.id}>
                      {gameFormat.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select name="gameType" label="Game Type" error={errors.gameType}>
                  <MenuItem value={2}>Singles</MenuItem>
                  <MenuItem value={1}>Pairs</MenuItem>
                  <MenuItem value={3}>Triples</MenuItem>
                  <MenuItem value={4}>Quads</MenuItem>
                </Select>

                <CustomMultipleVs gameType={watchGameType} />
              </FormProvider>
            )}
          </Paper>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5" color="primary">
              Game Summary
            </Typography>
            {watchGameFormat !== '' && watchGameFormat !== undefined ? (
              <>
                <Typography variant="h6" mb={2}>
                  {gameFormatService.getGameTypeString(watchGameType)} - {selectedGameFormat?.name}
                </Typography>
                <>
                  {selectedGameFormat && <GameFormatDescription gameFormat={selectedGameFormat} />}
                </>
              </>
            ) : (
              <Typography m={2}>No Information yet... Start by selecting a Game Format</Typography>
            )}

            {methods.watch('teams')?.teamA?.players.length > 0 &&
            methods.watch('teams')?.teamB?.players.length > 0 ? (
              <Box my={2}>
                <Typography variant="h5" color="primary">
                  Players & Teams
                </Typography>

                <MultiplePlayerDisplay teams={methods.watch('teams')} />
              </Box>
            ) : null}

            <Box
              display={'flex'}
              flexDirection={'row'}
              width="100%"
              justifyContent={'flex-end'}
              gap={1}>
              {creatingGame ? (
                <CircularProgress />
              ) : (
                <>
                  <PrimaryButton
                    variant="contained"
                    color="success"
                    sx={{ fontWeight: 'bold' }}
                    onClick={() => methods.handleSubmit(handleSaveForLater)()}>
                    Save for Later
                  </PrimaryButton>
                  {/* <PrimaryButton variant="contained" color="primary" sx={{ fontWeight: 'bold' }} onClick={() => handleStartGame(game)}>
                    Start Game
                  </PrimaryButton> */}
                </>
              )}
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} my={2}>
              {errors.teams && errors.teams.message && (
                <Typography variant="caption" color="error">
                  {errors.teams && errors.teams.message}
                </Typography>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default NewGame;
