import React from "react";
import { Typography, Box } from "@mui/material";
import PrimaryButton from "../ui/buttons/primaryButton";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <Box display={"flex"} flexDirection={"column"} alightItems={"center"} p={2}>
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{ textAlign: "center" }}
      >
        Welcome to Bowlscout Admin
      </Typography>

      <Typography variant="body1" component="p" sx={{ textAlign: "center" }}>
        This is the homepage for BowlScout Admin.
      </Typography>

      <PrimaryButton
        variant="contained"
        color="primary"
        sx={{ margin: "auto", mt: 5 }}
        onClick={() => navigate("/signin")}
      >
        Sign In
      </PrimaryButton>
    </Box>
  );
};

export default Home;
