// React
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// UI
import {
  AppBar,
  Box,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';

// Services
import gameFormatService from '../../features/gameFormat/gameFormatService';
import PlayerViewDialog from '../players/playerDialog';
import TeamDialog from '../teams/teamDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventPlayerSetupDialog = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [openTeam, setOpenTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const event = useSelector((state) => state.event.event);
  const isSingles = event.gameFormat.gameType === gameFormatService.GameType.SINGLES;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlayerAdd = (player) => {
    setSelectedPlayer();
    setOpenPlayer(true);
  };

  const handlePlayerEdit = (player) => {
    setSelectedPlayer(player);
    setOpenPlayer(true);
  };

  const handleTeamAdd = (player) => {
    setSelectedTeam();
    setOpenTeam(true);
  };

  // const handleTeamEdit = (player) => {
  //   setSelectedTeam(player);
  //   setOpenTeam(true);
  // };

  return (
    <Fragment>
      <PrimaryButton onClick={handleOpen}>Setup Players</PrimaryButton>
      <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Player Setup
            </Typography>
            <PrimaryButton autoFocus color="inherit" onClick={handleClose}>
              Close
            </PrimaryButton>
          </Toolbar>
        </AppBar>

        <Box display={'flex'} justifyContent={'space-evenly'} sx={{ p: 2 }}>
          <Box display={'flex'} flexDirection={'column'} flexGrow={1} mx={1} my={2}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="h4">Players</Typography>
              <PrimaryButton variant={'outlined'} onClick={handlePlayerAdd} color="secondary">
                Add Player
              </PrimaryButton>
            </Box>

            <Box display="flex" flexDirection="column">
              {event.players?.length > 0 ? (
                event.players.map((player, index) => (
                  <List dense>
                    <ListItem key={player.id} dense>
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width="100%">
                        <Typography>
                          {player.name} {player.surname}
                        </Typography>
                        <IconButton onClick={() => handlePlayerEdit(player)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </Box>
                    </ListItem>
                  </List>
                ))
              ) : (
                <Typography variant="caption">No Players Found, add a payer...</Typography>
              )}
            </Box>
          </Box>

          {!isSingles && (
            <Box display={'flex'} flexDirection={'column'} flexGrow={2} mx={1} my={2}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h4">Teams</Typography>
                <PrimaryButton variant={'outlined'} onClick={handleTeamAdd} color="secondary">
                  Add Team
                </PrimaryButton>
              </Box>

              <Box display="flex" flexDirection="column">
                {event.teams?.length > 0 ? (
                  <List dense>
                    {event.teams.map((team, index) => (
                      <ListItem key={team.id} dense>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          width="100%">
                          <Typography>{`${index + 1}. ${team.name}`}</Typography>
                          <IconButton>
                            <EditIcon color="primary" />
                          </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="caption">No Teams Found, add a team...</Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <PlayerViewDialog
          event={event}
          player={selectedPlayer}
          open={openPlayer}
          setOpen={setOpenPlayer}
        />

        <TeamDialog open={openTeam} setOpen={setOpenTeam} />
      </Dialog>
    </Fragment>
  );
};

export default EventPlayerSetupDialog;
