import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth } from '../config/firebase';
import { store } from '../config/firebase';

export const getSignedInUser = async (userId) => {
  //Get User Profile from store
  const userRef = doc(store, 'users', userId);
  const userSnap = await getDoc(userRef);
  const user = userSnap.data();

  return user;
};

//Create user profile when signed up, the auth user will return a ID that needs to be used for the user id.
export const createUserProfile = async (userId, user) => {
  const userRef = doc(store, 'users', userId);
  //Include timestamps
  user.createdAt = new Date();
  user.updatedAt = new Date();

  await setDoc(userRef, user);
};
