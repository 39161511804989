import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicRoute from './hoc/publicRoute';
import Home from './components/home';
import SignIn from './components/account/signin';
import Dashboard from './components/dashboard';
import PrivateRoute from './hoc/privateRoute';
import Events from './components/events';
import Event from './components/events/event';
import Section from './components/section';
import SignUp from './components/account/signup';
import NewGame from './components/games/newGame';
import Scoring from './components/games/scoring';
import Scoreboard from './components/scoreboard';
import EmbeddedSectionsView from './components/embedded/sections';
import EmbeddedSectionsGames from './components/embedded/sectionGames';

// import PrivateRoute from './hoc/privateRoute';
// import PublicRoute from './hoc/publicRoute';

//Auth
// import ForgotPassword from './components/account/forgotPassword';
// import SignIn from './components/account/signin';
// import SignUp from './components/account/signup';
// import UpdatePassword from './components/account/updatePassword';

const MainRoutes = (props) => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path={'/dashboard'} element={<Dashboard />} />
        <Route path={'/new'} element={<NewGame />} />
        <Route path={'/events'} element={<Events />} />
        <Route path={'/events/:eventId'} element={<Event />} />
        <Route path={'/events/:eventId/section/:sectionId'} element={<Section />} />
        <Route path={'/events/:eventId/scoreboard'} element={<Scoreboard />} />
        <Route path={'/game/scoring/:gameId'} element={<Scoring />} />
      </Route>
      <Route element={<PublicRoute />}>
        <Route path={'/'} element={<Home />} />
        <Route path={'/signin'} element={<SignIn />} />
        <Route path={'/signup'} element={<SignUp />} />

        <Route path={'/embed/event/:eventId'} element={<EmbeddedSectionsView />} />
        <Route path={'/embed/games/:sectionId'} element={<EmbeddedSectionsGames />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
