import React, { useMemo } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from '../main.routes';
import { AuthProvider } from '../contexts/authContext';
import { AlertProvider } from '../contexts/alertContext';
import { createBowlscoutTheme } from '../resources/theme';

import ColorModeProvider, { useColorMode } from '../hooks/colorModeProvider';
import StagingAlert from './ui/stagingAlert';

function MyApp() {
  const { mode } = useColorMode();

  const theme = useMemo(
    () =>
      createBowlscoutTheme({
        mode
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertProvider>
        <StagingAlert isStaging={process.env.NODE_ENV !== 'production'} />
        <AuthProvider>
          <BrowserRouter>
            <MainRoutes />
          </BrowserRouter>
        </AuthProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}

function ToggleColorMode() {
  return (
    <ColorModeProvider>
      <MyApp />
    </ColorModeProvider>
  );
}

export default ToggleColorMode;
