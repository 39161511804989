// React
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setEvent, setLoadingEvent } from '../../features/event/eventSlice';

// Store
import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';

// UI
import { Box, CircularProgress, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddEditSectionDialog from './addEditSectionDialog';
import PrimaryButton from '../ui/buttons/primaryButton';
import EventSectionCard from '../ui/cards/eventSectionCard';
import EventPlayerSetupDialog from './eventPlayerSetupDialog';
import { getGameByEventId } from '../../features/game/gameSlice';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import AddGameDialog from '../games/addGameDialog';

const Event = () => {
  let { eventId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const dispatch = useDispatch();
  const event = useSelector((state) => state.event.event);
  const games = useSelector((state) => state.game.games);
  const loading = useSelector((state) => state.event.loading);

  const [open, setOpen] = useState(false);
  const [openAddEditSection, setOpenAddEditSection] = useState(false);
  const [openPlayerAdd, setOpenPlayerAdd] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(setLoadingEvent(true));
    const docRef = doc(store, 'events', eventId); // replace eventId with your actual event ID

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        dispatch(setEvent(eventData));

        if (eventData.hasSections && !eventData.sections) {
          setOpenAddEditSection(true);
        }

        dispatch(getGameByEventId(eventData.id));
      } else {
        console.log('No such document!');
      }

      dispatch(setLoadingEvent(false));
    });

    return () => unsubscribe();
  }, [eventId, dispatch]);

  const handleOpenAddGame = (section) => {
    setSelectedSection(section);
    setOpen(true);
  };

  const handlePlayerAddClose = () => {
    setOpenPlayerAdd(false);
  };

  const handleSectionAddEditClose = () => {
    setOpenAddEditSection(false);
  };

  const handleSectionAddEditOpen = () => {
    setOpenAddEditSection(true);
  };

  const handleViewSection = (section) => {
    navigate('/events/' + eventId + '/section/' + section.id);
  };

  return !event && loading ? (
    <Box
      display="flex"
      flexDirection={'column'}
      height={'100vh'}
      justifyContent="center"
      alignItems="center">
      <CircularProgress size={'60'} color={'primary'} />
    </Box>
  ) : (
    <Stack>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" component="h1" mb={3}>
          {event?.name}
        </Typography>

        <Box>
          <PrimaryButton
            variant={'contained'}
            color={'secondary'}
            sx={{ mr: 1 }}
            onClick={() => navigate(`/events/${eventId}/scoreboard`)}>
            Scoreboard
          </PrimaryButton>

          {event.hasSections ? (
            <PrimaryButton
              variant={'contained'}
              sx={{ mr: 1 }}
              onClick={() => handleSectionAddEditOpen()}>
              Add Section
            </PrimaryButton>
          ) : (
            <AddGameDialog event={event} />
          )}

          <EventPlayerSetupDialog event={event} />
        </Box>
      </Box>

      <Grid container>
        {event.hasSections && (
          <Grid xs={12}>
            <Typography mb={2}>Sections</Typography>
            <Box display="flex" flexWrap={'wrap'}>
              {event.sections?.map((section, index) => (
                <EventSectionCard
                  key={index}
                  section={section}
                  event={event}
                  onAddGame={() => handleOpenAddGame(section)}
                  onViewSection={() => handleViewSection(section)}
                />
              ))}
            </Box>
          </Grid>
        )}

        <Grid xs={12}>
          <Typography mb={2}>All Games</Typography>
          <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2}>
            {games && games.length > 0 ? (
              games.map((game, index) => <GameByStatusCards game={game} key={index} />)
            ) : (
              <Typography>No Games</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <AddEditSectionDialog
        open={openAddEditSection}
        onClose={() => handleSectionAddEditClose()}
        event={event}
      />
    </Stack>
  );
};

export default Event;
