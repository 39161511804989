import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import eventService from './eventService';

export const getAllEvents = createAsyncThunk('event/getAllEvents', async (data, thunkAPI) => {
  return await eventService
    .get()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getEvent = createAsyncThunk('event/getEvent', async (data, thunkAPI) => {
  return await eventService
    .getById(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const createEvent = createAsyncThunk('event/createEvent', async (data, thunkAPI) => {
  return await eventService
    .create(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const updateEvent = createAsyncThunk('event/updateEvent', async (data, thunkAPI) => {
  return await eventService
    .update(data.id, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const deleteEvent = createAsyncThunk('event/deleteEvent', async (data, thunkAPI) => {
  return await eventService
    .remove(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const addEventGame = createAsyncThunk('event/addEventGame', async (data, thunkAPI) => {
  return await eventService
    .addGame(data.eventId, data.sectionId, data.game)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const eventSlice = createSlice({
  name: 'events',
  initialState: {
    loading: true,
    event: null,
    events: [],
    addGameLoading: false
  },
  reducers: {
    setLoadingEvent: (state, action) => {
      state.loading = action.payload;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.events = action.payload;
        state.loading = false;
      })
      .addCase(getAllEvents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.loading = false;
      })
      .addCase(getEvent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events.push(action.payload);
        state.saving = false;
      })
      .addCase(createEvent.pending, (state, action) => {
        state.saving = true;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.saving = false;
      })
      .addCase(addEventGame.fulfilled, (state, action) => {
        state.addGameLoading = false;
      })
      .addCase(addEventGame.pending, (state, action) => {
        state.addGameLoading = true;
      })
      .addCase(addEventGame.rejected, (state, action) => {
        state.addGameLoading = false;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.saving = false;
      })
      .addCase(updateEvent.pending, (state, action) => {
        state.saving = true;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.saving = false;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter((event) => event.id !== action.payload.eventId);
        state.loading = false;
      })
      .addCase(deleteEvent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export const { setEvent, setLoadingEvent } = eventSlice.actions;

export default eventSlice.reducer;
