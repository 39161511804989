import { Box, Stack, Typography } from '@mui/material';
import TeamEndScore from './teamEndScore';
import TeamFinalScore from './teamFinalScore';
import GameControl from '../gameControls/gameControl';
import ScoreControl from '../gameControls/scoreControl';
import PlayoffScoreControl from '../gameControls/playoffScoreControl';
import { GameStatus } from '../../../constants/gameConstants';
import GameControlSecondary from '../gameControls/gameControlSecondary';

const ScoringDisplay = ({ game }) => {
  return game && game.teams ? (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={'space-evenly'}
        alignItems={'center'}
        gap={1}>
        <Stack alignItems={'center'}>
          {game.status === GameStatus.COMPLETE ? (
            <TeamFinalScore team={game.teams.teamA} game={game} />
          ) : (
            <TeamEndScore team={game.teams.teamA} game={game} />
          )}

          {game.status === GameStatus.PLAYOFF && game.playoff ? (
            <PlayoffScoreControl game={game} team={game.teams.teamA} />
          ) : (
            <ScoreControl team={game.teams.teamA} game={game} />
          )}
        </Stack>
        <GameControl game={game} />
        <Stack alignItems={'center'}>
          {game.status === GameStatus.COMPLETE ? (
            <TeamFinalScore team={game.teams.teamB} game={game} />
          ) : (
            <TeamEndScore team={game.teams.teamB} game={game} />
          )}

          {game.status === GameStatus.PLAYOFF && game.playoff ? (
            <PlayoffScoreControl game={game} team={game.teams.teamB} />
          ) : (
            <ScoreControl team={game.teams.teamB} game={game} />
          )}
        </Stack>
      </Box>
      <GameControlSecondary game={game} />
    </>
  ) : null;
};

export default ScoringDisplay;
