import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Forms
import gameFormatService from '../../features/gameFormat/gameFormatService';

//UI
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PrimaryButton from '../ui/buttons/primaryButton';
import { generateTeamName } from '../../helpers/teams';

import eventService from '../../features/event/eventService';

const TeamDialog = ({ open, setOpen, addTeam }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [error, setError] = useState(null);

  const theme = useTheme();
  const event = useSelector((state) => state.event.event);

  useEffect(() => {
    if (event.players) {
      setPlayers(
        event.players.map((player) => ({
          label: `${player.name} ${player.surname}`,
          value: player.id
        }))
      );
    }

    return () => {
      setTeamMembers([]);
      setPlayers([]);
    };
  }, [event]);

  const handleAddTeamMember = (member) => {
    if (member) {
      const player = event.players.find((p) => p.id === member.value);
      setSelectedMember(player);
    }
  };

  const handleOnAdd = (target) => {
    if (target.code === 'Enter' && selectedMember) {
      setError(null);
      const limit = gameFormatService.getGameTypeLimit(event.gameFormat.gameType) / 2;

      const existsInList = teamMembers.filter((tm) => tm.id === selectedMember.id).length > 0;
      const limitReached = teamMembers.length >= limit;

      if (!existsInList && !limitReached) {
        setTeamMembers((prevMembers) => [...prevMembers, selectedMember]);
        setSelectedMember(null);
      } else {
        existsInList ?? setError('Already in list.');
        limitReached ?? setError('Limit Reached.');
      }
    }
  };

  const hanldeRemoveTeamMember = (player) => {
    setTeamMembers(teamMembers.filter((tm) => tm.id !== player.id));
  };

  const handleCreateTeam = () => {
    const team = {
      members: teamMembers,
      name: generateTeamName(event.gameFormat.gameType, teamMembers)
    };

    eventService.addTeam(event.id, team);
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
      <DialogTitle>Select Team Members</DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'column'}>
          {/* Team A */}
          <Autocomplete
            sx={{ pt: 1 }}
            disablePortal
            id="team-a-player-search"
            options={players}
            onChange={(event, value) => handleAddTeamMember(value)}
            onKeyUp={(event) => handleOnAdd(event)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Start Typing"
                helperText={error ? error : 'Press Enter to add'}
                color={error ? 'error' : 'info'}
              />
            )}
          />

          <Paper
            sx={{
              backgroundColor: theme.palette.background.default,
              mt: 1,
              minHeight: '150px'
            }}>
            <List>
              {teamMembers.length > 0 ? (
                teamMembers.map((member) => (
                  <ListItem
                    key={member.id}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        color="error"
                        onClick={() => hanldeRemoveTeamMember(member)}>
                        <DeleteIcon />
                      </IconButton>
                    }>
                    {member.name} {member.surname}
                  </ListItem>
                ))
              ) : (
                <Typography m={2} variant={'caption'}>
                  There is no players in this team yet...
                </Typography>
              )}
            </List>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        {teamMembers && teamMembers.length > 0 ? (
          <Typography>{generateTeamName(event.gameFormat.gameType, teamMembers)}</Typography>
        ) : null}
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <PrimaryButton onClick={() => handleCreateTeam()} variant={'contained'}>
          Create Team
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default TeamDialog;
