import { configureStore } from '@reduxjs/toolkit';
import eventReducer from '../features/event/eventSlice';
import venueReducer from '../features/venue/venueSlice';
import gameFormatReducer from '../features/gameFormat/gameFormatSlice';
import gameReducer from '../features/game/gameSlice';
import leaderboardReducer from '../features/leaderboard/leaderboardSlice';

export default configureStore({
  reducer: {
    event: eventReducer,
    venue: venueReducer,
    game: gameReducer,
    gameFormat: gameFormatReducer,
    leaderboard: leaderboardReducer
  },
  //devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
