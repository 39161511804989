import { Box, Typography } from '@mui/material';
import gameFormatService from '../../features/gameFormat/gameFormatService';

const GameFormatDescription = ({ gameFormat }) => {
  return (
    <>
      <Box>
        <Typography variant="body1">Format: {gameFormat.name}</Typography>
        <Typography variant="body1">
          Game Type: {gameFormatService.getGameTypeString(gameFormat.gameType)}
        </Typography>

        <Typography variant="body1">
          Ends: {gameFormat.ends === 0 ? 'N/A' : gameFormat.ends}{' '}
        </Typography>

        <Typography variant="body1">Max Score: {gameFormat.maxScore}</Typography>
        <Typography variant="body1">
          Include Handicap: {gameFormat.includeHandicap ? 'Yes' : 'No'}
        </Typography>

        <Typography variant="body1">Sets: {gameFormat.sets}</Typography>
        <Typography variant="body1">Max Point Per Set: {gameFormat.maxTotalPerSet}</Typography>

        <Typography variant="body1">Number of Bowls: {gameFormat.numBowls}</Typography>
        <Typography variant="body1">Playoff: {gameFormat.playoffs ? 'Yes' : 'No'}</Typography>

        {gameFormat.playoffs && (
          <>
            <Typography variant="body1">
              Playoff Max Points: {gameFormat.playoffMaxPoints}
            </Typography>
            <Typography variant="body1">Playoff Point: {gameFormat.playoffPoint}</Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default GameFormatDescription;
