// Updated Theme Version 1.1
import { createTheme } from '@mui/material/styles';

const greenBase = '#4caf50';
const redBase = '#f44336';
const blueBase = '#2196f3';
const yellowBase = '#ffeb3b';

const getPaletteMode = (mode) => {
  return mode.mode === 'light'
    ? {
        primary: {
          main: '#0a0e21'
        },
        secondary: {
          main: '#9c27b0'
        },
        warning: {
          main: '#ff5722'
        },
        error: {
          main: '#e82525'
        },
        success: {
          main: '#24d876'
        },
        info: {
          main: '#2196f3'
        }
      }
    : {
        primary: {
          main: '#eb1555'
        },
        secondary: {
          main: '#9c27b0'
        },
        background: {
          main: '#282B3C',
          default: '#282B3C',
          paper: '#0a0e21'
        },
        warning: {
          main: '#ff5722'
        },
        error: {
          main: '#e82525'
        },
        success: {
          main: '#24d876'
        },
        info: {
          main: '#2196f3'
        },
        text: {
          primary: '#ffffff',
          secondary: '#FFFFFF'
        },
        lightIcon: {
          main: '#FFFFFF'
        }
      };
};

let createBowlscoutTheme = (mode) => {
  const palette = getPaletteMode(mode);
  return createTheme({
    palette: {
      ...palette,
      green: {
        main: greenBase
      },
      red: {
        main: redBase
      },
      blue: {
        main: blueBase
      },
      yellow: {
        main: yellowBase
      }
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: mode.mode === 'dark' ? '#282B3C' : '#fff' // change these colors as needed
          }
        }
      }
    }
  });
};

export { createBowlscoutTheme };
