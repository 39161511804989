import React from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import Scoreboard from '../ui/gameControls/scoreboard';
import ScoringDisplay from '../ui/playerDisplay/scoringDisplay';

const Scoring = () => {
  const { gameId } = useParams();
  const [fsGame, setFSGame] = React.useState({});

  React.useEffect(() => {
    const docRef = doc(store, 'games', gameId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const gameData = { id: docSnap.id, ...docSnap.data() };
        setFSGame(gameData);
      } else {
        console.log('No game found!');
      }
    });

    return () => unsubscribe();
  }, [gameId]);

  return fsGame ? (
    <Box>
      <Typography variant="h4" textAlign={'center'}>
        {fsGame.name ?? 'Private Game'}
      </Typography>
      <ScoringDisplay game={fsGame} />

      {fsGame.sets && <Scoreboard game={fsGame} />}

      <Box flexDirection={'column'} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Typography variant="caption" textAlign={'center'}>
          Event ID: {fsGame.event}
        </Typography>
        <Typography variant="caption" textAlign={'center'}>
          Game ID: {fsGame.id}
        </Typography>
      </Box>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default Scoring;
