import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "./privateLayout";

import { useAuth } from "../contexts/authContext";

const PrivateRoute = (props) => {
  const { authenticated } = useAuth();

  if (!authenticated) {
    return <Navigate to={"/signin"} />;
  }

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default PrivateRoute;
