import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Radio,
  Typography
} from '@mui/material';
import { useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from '../ui/forms/fields/input';
import Checkbox from '../ui/forms/fields/checkbox';
import Select from '../ui/forms/fields/select';

const schema = yup.object().shape({
  ends: yup.number().required('Ends is required'),
  gameType: yup.number().required('Game Type is required'),
  maxScore: yup.number().required('Max Score is required'),
  includeHandicap: yup.boolean().required('Include Handicap is required'),
  sets: yup.number().required('Sets is required'),
  maxTotalPerSet: yup.number().required('Max Points Per Set is required'),
  winPoints: yup.number().required('Win Points is required'),
  numBowls: yup.number().required('Number of Bowls is required'),
  playoffs: yup.boolean().required('Playoffs is required'),
  playoffMaxPoints: yup.number(),
  playoffPoint: yup.number()
});

const OverrideGameFormat = ({ gameFormat, onChange }) => {
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });
  const playoffWatch = watch('playoffs');

  const handleSave = (data) => {
    onChange({ ...gameFormat, ...data });
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="blue" sx={{ mt: 2 }} onClick={() => setOpen(true)}>
        Override Format Settings
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Override Game Format Settings</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Override the settings for this game format.</Typography>

          <Select
            name="gameType"
            label="Game Type"
            error={errors.gameType}
            control={control}
            defaultValue={gameFormat.gameType}>
            <MenuItem value={2}>Singles</MenuItem>
            <MenuItem value={1}>Pairs</MenuItem>
            <MenuItem value={3}>Triples</MenuItem>
            <MenuItem value={4}>Quads</MenuItem>
          </Select>

          <Input
            id="ends"
            label="Ends"
            type="number"
            defaultValue={gameFormat.ends}
            error={errors.ends}
            helperText="Select the number of ends, for unlimited ends enter '0'"
            sx={{ mt: 2 }}
            {...register('ends')}
          />
          <Input
            id="`maxScore`"
            label="Max Score"
            type="number"
            defaultValue={gameFormat.maxScore}
            error={errors.maxScore}
            helperText="Enter the max score for the game, for unlimited score enter '0'. Set higher than 0 when ends are unlimited."
            sx={{ mt: 2 }}
            {...register('maxScore')}
          />

          <Input
            id="sets"
            label="Sets"
            type="number"
            defaultValue={gameFormat.sets}
            error={errors.sets}
            helperText={
              'Enter the number of sets to be played, the default is 1 for any game format.'
            }
            sx={{ mt: 2 }}
            {...register('sets')}
          />
          <Input
            id="maxTotalPerSet"
            label="Points per set"
            type="number"
            defaultValue={gameFormat.maxTotalPerSet}
            error={errors.maxTotalPerSet}
            helperText={'Enter the set point for winning the set.'}
            sx={{ mt: 2 }}
            {...register('maxTotalPerSet')}
          />
          <Input
            id="winPoints"
            label="Win Points"
            type="number"
            defaultValue={gameFormat.winPoints}
            error={errors.winPoints}
            helperText={'Enter the winning game point(s).'}
            sx={{ mt: 2 }}
            {...register('winPoints')}
          />
          <Input
            id="numBowls"
            label="Number of Bowls"
            type="number"
            defaultValue={gameFormat.numBowls}
            error={errors.numBowls}
            helperText={'Enter the number of bowls per player.'}
            sx={{ mt: 2 }}
            {...register('numBowls')}
          />

          <Checkbox
            name={'includeHandicap'}
            label="Include Handicap"
            defaultValue={gameFormat.includeHandicap}
            control={control}
            error={errors.includeHandicap}
            value={gameFormat?.includeHandicap}
          />
          <Checkbox
            name={'playoffs'}
            label="Playoffs"
            defaultValue={gameFormat.playoffs}
            control={control}
            error={errors.playoffs}
            //disabled={saving}
            value={gameFormat?.playoffs}
          />

          {playoffWatch && (
            <>
              <Input
                id="playoffMaxPoints"
                label="Playoff Max Points"
                type="number"
                defaultValue={gameFormat.playoffMaxPoints}
                error={errors.playoffMaxPoints}
                helperText={'Enter the max point(s) that can be accumulated in a playoff game.'}
                sx={{ mt: 2 }}
                {...register('playoffMaxPoints')}
              />
              <Input
                id="playoffPoint"
                label="Playoff Point"
                type="number"
                defaultValue={gameFormat.playoffPoint}
                error={errors.playoffPoint}
                helperText={'Enter the playoff game point.'}
                sx={{ mt: 2 }}
                {...register('playoffPoint')}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => handleSubmit(handleSave)()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OverrideGameFormat;
