import { Stack, Typography, Badge, Box } from '@mui/material';
import { GameStatus } from '../../../constants/gameConstants';
import ScoreBox from './scoreBox';
import ColorDot from '../colorDot';

const TeamNameHeaders = ({ children, team }) => {
  const teamName = team.players
    .map((player) => {
      if (team.players.length > 2) {
        return player.split(' ')[0].charAt(0) + '. ' + player.split(' ')[1];
      } else {
        return player;
      }
    })
    .join(', ');

  return (
    <Box display="flex" flexDirection={'column'}>
      <ColorDot color={team.color} />
      <Stack px={3} alignItems={'center'} justifyContent={'center'}>
        <Typography variant="caption">{teamName}</Typography>
        {children}
      </Stack>
    </Box>
  );
};

export default TeamNameHeaders;
