import { Box, Typography } from '@mui/material';
import TeamEndScore from './teamEndScore';
import { GameStatus } from '../../../constants/gameConstants';
import TeamFinalScore from './teamFinalScore';

const ScoreDisplay = ({ game }) => {
  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const getElementByGameStatus = (team) => {
    switch (game.status) {
      case GameStatus.COMPLETE:
        return <TeamFinalScore team={team} game={game} />;
      case GameStatus.IN_PROGRESS:
        return currentSet.isDone && currentSet.winningId ? (
          <TeamFinalScore team={team} game={game} />
        ) : (
          <TeamEndScore team={team} game={game} />
        );
      case GameStatus.PLAYOFF:
        return <TeamFinalScore team={team} game={game} />;
      default:
        return <TeamEndScore team={team} game={game} />;
    }
  };

  return game && game.teams ? (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={'space-evenly'}
      alignItems={'center'}
      gap={1}>
      {getElementByGameStatus(game.teams.teamA)}
      <Typography>VS</Typography>
      {getElementByGameStatus(game.teams.teamB)}
    </Box>
  ) : null;
};

export default ScoreDisplay;
