//Add Edit event schema with yup and reacthook form

import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEvent, createEvent } from '../../features/event/eventSlice';
import venueService from '../../features/venue/venueService';
import gameFormatService from '../../features/gameFormat/gameFormatService';

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../ui/buttons/primaryButton';

import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import DatePicker from '../ui/forms/fields/datePicker';
import Checkbox from '../ui/forms/fields/checkbox';
import moment from 'moment';
import GameFormatDescription from '../gameFormats/description';
import OverrideGameFormat from '../gameFormats/override';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  startDate: yup.string().required('Start Date is required'),
  endDate: yup.string().required('End Date is required'),
  gameFormat: yup.string().required('Game Format is required'),
  venue: yup.string(),
  hasSections: yup.bool().required('Has Sections is required')
});

const AddEditEventDialog = ({ open, handleClose, event }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.event.saving);
  const [venues, setVenues] = React.useState([]);
  const [gameFormats, setGameFormats] = React.useState([]);
  const [selectedGameFormat, setSelectedGameFormat] = React.useState(null);

  useEffect(() => {
    getVenues();
    getGameFormats();
  }, []);

  const watchGameFormat = watch('gameFormat');
  useEffect(() => {
    if (watchGameFormat) {
      const gameFormatObject = gameFormats.find((gf) => gf.id === watchGameFormat);
      setSelectedGameFormat(gameFormatObject);
    }
  }, [watchGameFormat]);

  const getVenues = async () => {
    const venues = await venueService.getAll();
    setVenues(venues);
  };

  const getGameFormats = async () => {
    const gameFormats = await gameFormatService.getAll();
    setGameFormats(gameFormats);
  };

  const onSubmit = async (data) => {
    if (data.venue === 'na') {
      data.venue = null;
    } else if (data.venue === 'multiple') {
      data.venue = { name: 'Multiple' };
    } else {
      const venueObject = venues.find((venue) => venue.id === data.venue);
      data.venue = venueObject;
    }

    let gameFormatObject = gameFormats.find((gf) => gf.id === data.gameFormat);
    console.log('Game Format Object', gameFormatObject);

    if (selectedGameFormat) {
      gameFormatObject = { ...gameFormatObject, ...selectedGameFormat };
    }

    console.log('Override Game Format', selectedGameFormat);
    data.gameFormat = gameFormatObject;

    if (event) {
      dispatch(updateEvent(event))
        .unwrap()
        .then((response) => {
          handleClose();
        })
        .catch((error) => {});
    } else {
      dispatch(createEvent(data))
        .unwrap()
        .then((response) => {
          reset({
            name: '',
            description: '',
            startDate: moment(),
            endDate: moment(),
            venue: '',
            gameFormat: '',
            hasSections: false
          });
          handleClose();
        })
        .catch((error) => {});
    }
  };

  const handleAddEdit = () => {
    handleSubmit(onSubmit)();
  };

  const handleGameFormatChange = (gameFormat) => {
    setSelectedGameFormat(gameFormat);
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'md'}>
      <DialogTitle variant="h4" component="h1">
        {event ? 'Edit Event' : 'New Event'}
      </DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
          <Box>
            <Input
              label="Name"
              {...register('name')}
              error={errors.name}
              disabled={saving}
              defaultValue={event?.name}
            />
            <Input
              label="Description"
              {...register('description')}
              error={errors.description}
              disabled={saving}
              defaultValue={event?.description}
            />
            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ mt: 2, mb: 2 }}>
              <DatePicker
                name={'startDate'}
                label="Start Date"
                control={control}
                defaultValue={moment(event?.startDate)}
                error={errors.startDate}
                format={'DD/MM/YYYY'}
                sx={{ mr: 1 }}
                disabled={saving}
              />
              <DatePicker
                name={'endDate'}
                label="End Date"
                control={control}
                defaultValue={moment(event?.endDate)}
                error={errors.endDate}
                format={'DD/MM/YYYY'}
                disabled={saving}
              />
            </Box>
            <Select
              label="Venue"
              name="venue"
              control={control}
              error={errors.venue}
              disabled={saving}
              defaultValue={event?.venue?.id}>
              <MenuItem value={'na'}>Not Applicable</MenuItem>
              <MenuItem value={'multiple'}>Multiple</MenuItem>
              {venues.map((venue) => (
                <MenuItem key={venue.id} value={venue.id}>
                  {venue.name}
                </MenuItem>
              ))}
            </Select>

            <Select
              label="Game Format"
              name="gameFormat"
              control={control}
              error={errors.venue}
              disabled={saving}
              defaultValue={event?.gameFormat.id}>
              {gameFormats.map((format) => (
                <MenuItem key={format.id} value={format.id}>
                  {format.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {watchGameFormat && (
            <Stack ml={2}>
              <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                Game Format
              </Typography>

              {selectedGameFormat && (
                <>
                  <GameFormatDescription gameFormat={selectedGameFormat} />
                  <OverrideGameFormat
                    gameFormat={selectedGameFormat}
                    onChange={(gf) => handleGameFormatChange(gf)}
                  />
                </>
              )}
            </Stack>
          )}
        </Box>

        <Stack>
          <Typography variant="h6" component="h2" mt={3}>
            Other Settings
          </Typography>
          <Checkbox
            name={'hasSections'}
            label="Sectional Play"
            control={control}
            error={errors.hasSections}
            disabled={saving}
            value={event?.hasSections}
            // checked={event?.hasSections}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        {saving ? (
          <CircularProgress />
        ) : (
          <Fragment>
            <PrimaryButton variant={'outlined'} color={'error'} onClick={() => handleClose()}>
              Cancel
            </PrimaryButton>
            <PrimaryButton variant={'contained'} onClick={() => handleAddEdit()}>
              {event ? 'Update' : 'Create'}
            </PrimaryButton>
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditEventDialog;
