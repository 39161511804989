import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PrimaryButton from '../ui/buttons/primaryButton';
import GameNotStartedCard from '../ui/gameStatusCards/gameNotStartedCard';
import { useEffect, useState } from 'react';
import { setLoadingEvent } from '../../features/event/eventSlice';
import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import { getGameBySectionId } from '../../features/game/gameSlice';

const Section = () => {
  const dispatch = useDispatch();
  const { eventId, sectionId } = useParams();
  const [section, setSection] = useState();
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState();

  const sectionGames = useSelector((state) => state.game.games);

  useEffect(() => {
    setLoading(true);
    const docRef = doc(store, 'events', eventId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        setEvent(eventData);
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [eventId]);

  useEffect(() => {
    if (event && event.sections && event.sections.length > 0) {
      const section = event.sections.find((section) => section.id === sectionId);
      setSection(section);

      dispatch(getGameBySectionId(sectionId));
    }
  }, [event, sectionId]);

  return section ? (
    <Box>
      <Typography variant="h4" gutterBottom>
        {section.name}
      </Typography>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2}>
        {sectionGames && sectionGames.length > 0 ? (
          sectionGames.map((game, index) => <GameByStatusCards game={game} key={index} />)
        ) : (
          <Typography>No Games</Typography>
        )}
      </Box>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default Section;
