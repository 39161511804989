import React, { Fragment, useEffect, useState, useSyncExternalStore } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteEvent, getAllEvents } from '../../features/event/eventSlice';
import {
  Typography,
  Box,
  Paper,
  Link,
  CircularProgress,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PrimaryButton from '../ui/buttons/primaryButton';
// import { store } from '../../config/firebase';
// import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import AddEditEventDialog from './addEditEventDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Dialog } from '@mui/material';
import moment from 'moment';

const Events = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.event.events);
  const loadingEvents = useSelector((state) => state.event.loading);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  const handleEditOpen = async (event) => {
    setSelectedEvent(event);
    setOpenEdit(true);
  };

  const handleEditClose = async () => {
    setSelectedEvent(null);
    setOpenEdit(false);
  };

  const handleDeleteClose = async () => {
    setOpenDelete(false);
  };

  const handleDeleteOpen = async (event) => {
    setSelectedEvent(event);
    setOpenDelete(true);
  };

  const handleConfirm = async () => {
    if (selectedEvent) {
      dispatch(deleteEvent(selectedEvent.id));
    }

    setOpenDelete(false);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mb={2}>
          <Typography variant="h4" component="h1">
            Events
          </Typography>
          <Box>
            <PrimaryButton variant={'contained'} sx={{ mr: 1 }} onClick={() => handleEditOpen()}>
              Create Event
            </PrimaryButton>
            <PrimaryButton variant={'contained'} color="secondary">
              Import Event
            </PrimaryButton>
          </Box>
        </Box>

        <AddEditEventDialog
          open={openEdit}
          handleClose={() => handleEditClose()}
          event={selectedEvent}
        />

        <Grid container spacing={2}>
          <Grid xs={3}>
            <Typography fontWeight={'600'}>Name</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography fontWeight={'600'}>Date</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography fontWeight={'600'}>Location</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography fontWeight={'600'}>Game Format</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography fontWeight={'600'}>Action</Typography>
          </Grid>
        </Grid>

        <Box mb={1} />

        {!loadingEvents ? (
          events.map((event, index) => (
            <Fragment key={index}>
              <Grid container spacing={2}>
                <Grid xs={3}>
                  <Link href={`/events/${event.id}`} color="inherit">
                    {event.name}
                  </Link>
                </Grid>
                <Grid xs={3}>
                  {event.startDate ? moment(event.startDate).format('DD/MM/YYYY') : ''}
                </Grid>
                <Grid xs={2}>{event.venue?.name}</Grid>
                <Grid xs={2}>{event.gameFormat?.name}</Grid>
                <Grid xs={2}>
                  <IconButton onClick={() => handleEditOpen(event)}>
                    <EditIcon color={'primary'} />
                  </IconButton>

                  <IconButton onClick={() => handleDeleteOpen(event)}>
                    <DeleteIcon color={'error'} />
                  </IconButton>
                </Grid>
              </Grid>
            </Fragment>
          ))
        ) : (
          <Box
            display="flex"
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            m={5}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{selectedEvent ? selectedEvent.name : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton variant="contained" onClick={handleDeleteClose}>
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleConfirm} autoFocus>
            Confirm
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Events;
