import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, CircularProgress, useTheme } from '@mui/material';
import { getGamesByUserId } from '../../features/game/gameSlice';
import GameNotStartedCard from '../ui/gameStatusCards/gameNotStartedCard';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { auth } from '../../config/firebase';

const Dashboard = () => {
  //const dispatch = useDispatch();
  const theme = useTheme();
  //const games = useSelector((state) => state.game.games);
  //const loading = useSelector((state) => state.game.loading);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const { uid } = auth.currentUser;

  const isMobile = theme.breakpoints.down('sm');

  //Get games by user id strait from the store
  useEffect(() => {
    if (!uid) {
      setLoading(false);
      return; // Ensure uid is available
    }

    console.log(uid);

    const q = query(collection(store, 'games'), where('uid', '==', uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const games = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.event) {
          // Check if 'event' is null or undefined
          games.push({ id: doc.id, ...data });
        }
      });

      console.log(games);
      setGames(games);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [uid]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Typography variant="h4" component="h1" mb={1}>
        Private Games
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          {games.map((game) => (
            <GameByStatusCards game={game} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
