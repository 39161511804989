import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

//Center login Box on screen with background image.
const Layout = (props) => {
  const theme = useTheme();

  return (
        <Box width={'100%'}>{props.children}</Box>
  );
};

export default Layout;

//props.children;
