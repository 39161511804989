import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Box,
  Typography
} from '@mui/material';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import { inputSetScore } from '../../features/game/gameSlice';
import TeamNameHeaders from '../ui/playerDisplay/teamNameHeaders';

const schema = yup.object().shape({
  teamATotalScore: yup.number().required('Team 1 Score is required'),
  teamBTotalScore: yup.number().required('Team 2 Score is required'),
  selectedSet: yup.number().required('Sets is required').default(1)
});

const InputScoreDialog = ({ game, onOpen }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const totalSets = game.gameFormat.sets;

  const teamA = game.teams.teamA;
  const teamB = game.teams.teamB;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = (data) => {
    data.game = game;
    data.gameId = game.id;
    data.totalSetScores = {
      [teamA.id]: data.teamATotalScore,
      [teamB.id]: data.teamBTotalScore
    };

    dispatch(inputSetScore(data))
      .unwrap()
      .then(() => {
        setOpen(false);
        onOpen();
      });
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => handleClickOpen()}>
        Input Score
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Score Game - {game.name ?? 'Private Game'}</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {totalSets > 1 && (
            <Select control={control} label="Sets" name="selectedSet">
              {Array.from({ length: game.gameFormat.sets }, (_, index) => (
                <MenuItem key={index} value={index + 1}>
                  Set {index + 1}
                </MenuItem>
              ))}
            </Select>
          )}

          <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
            <Paper
              sx={{
                width: '160px',
                alignSelf: 'center',
                borderRadius: 2,
                p: 1,
                backgroundColor: 'background.main'
              }}>
              <TeamNameHeaders team={teamA}>
                <Input
                  {...register('teamATotalScore')}
                  defaultValue={currentSet.totalSetScores ? currentSet.totalSetScores[teamA.id] : 0}
                  helperText={'Enter Score'}
                  error={!!errors.teamATotalScore}
                />
              </TeamNameHeaders>
            </Paper>

            <Paper
              sx={{
                width: '160px',
                alignSelf: 'center',
                borderRadius: 2,
                p: 1,
                backgroundColor: 'background.main'
              }}>
              <TeamNameHeaders team={teamB}>
                <Input
                  {...register('teamBTotalScore')}
                  defaultValue={currentSet.totalSetScores ? currentSet.totalSetScores[teamB.id] : 0}
                  helperText={'Enter Score'}
                  error={!!errors.teamBTotalScore}
                />
              </TeamNameHeaders>
            </Paper>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
            <Typography variant="caption" color="error">
              {errors.selectedSet?.message}
            </Typography>
            <Typography variant="caption" color="error">
              {errors.teamATotalScore?.message}
            </Typography>
            <Typography variant="caption" color="error">
              {errors.teamBTotalScore?.message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button variant="contained" onClick={() => handleSubmit(submit)()} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InputScoreDialog;
