import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "./publicLayout";
import { useAuth } from "../contexts/authContext";

const PublicRoute = (props) => {
  const { authenticated } = useAuth();

  if (authenticated) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
