import axiosInstance from '../axiosInstance';

const getEventSectionsLeaderboard = async (eventId) => {
  return axiosInstance.get(`/leaderboard/event/${eventId}/sectionleaderboard`).then((response) => {
    return response.data;
  });
};

const leaderboardService = {
  getEventSectionsLeaderboard
};

export default leaderboardService;
