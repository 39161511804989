//Add Edit event schema with yup and reacthook form

import React, { Fragment } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PrimaryButton from "../ui/buttons/primaryButton";
import eventService from "../../features/event/eventService";

import Input from "../ui/forms/fields/input";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  rounds: yup
    .number()
    .default(1)
    .required("Rounds is required.")
    .min(1, "Rounds must be greater than 0"),
});

const AddEditSectionDialog = ({ open, onClose, event, section }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: section,
  });

  const [saving, setSaving] = React.useState(false);

  const onSubmit = async (data) => {
    setSaving(true);

    if (event && section) {
      //await eventService.update(event.id, data);
    } else if (event) {
      await eventService.addSection(event.id, data);
    }

    setSaving(false);
    onClose();
  };

  const handleAddEdit = () => {
    handleSubmit(onSubmit)();
  };

  const handleAutoGenerateName = () => {
    //Get number of sections in Event
    const numSections = event.sections?.length ?? 0;

    //Generate Section Name
    const name = `Section ${numSections + 1}`;

    //Set the value of the name field
    setValue("name", name, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h4" component="h1">
          {section ? "Edit Section" : "New Section"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box display="flex" flexDirection={"row"} alignItems="center">
            <Input
              label="Name"
              {...register("name")}
              error={errors.name}
              disabled={saving}
              focused={true}
            />
            <PrimaryButton
              variant="text"
              color="secondary"
              sx={{ ml: 1, width: "250px", height: "40px" }}
              onClick={() => handleAutoGenerateName()}
            >
              Auto Generate
            </PrimaryButton>
          </Box>
          <Input
            label="Rounds"
            {...register("rounds")}
            error={errors.rounds}
            disabled={saving}
            type="number"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {saving ? (
          <CircularProgress />
        ) : (
          <Fragment>
            <PrimaryButton
              variant={"outlined"}
              color={"error"}
              onClick={onClose}
            >
              Cancel
            </PrimaryButton>
            <PrimaryButton
              variant={"contained"}
              onClick={() => handleAddEdit()}
            >
              {section ? "Update" : "Add"}
            </PrimaryButton>
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditSectionDialog;
