import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';
import MultiplePlayerDisplay from '../playerDisplay/multiple';
import { startGame } from '../../../features/game/gameSlice';

const GameNotStartedCard = ({ event, game, actions }) => {
  const dispatch = useDispatch();

  const handleStartGame = () => {
    dispatch(startGame(game.id))
      .unwrap()
      .then(() => {
        //navigate(`/game/scoring/${game.id}`);
      });
  };

  return (
    <Stack justifyContent={'space-between'} height="100%">
      <MultiplePlayerDisplay teams={game.teams} />

      {actions && (
        <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} justifySelf={'flex-end'}>
          <PrimaryButton color="success" onClick={() => handleStartGame()}>
            Start Game
          </PrimaryButton>
          <PrimaryButton>Edit</PrimaryButton>
        </Box>
      )}
    </Stack>
  );
};

export default GameNotStartedCard;
