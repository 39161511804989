import { CircularProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVenuesByUserId, setSelectedVenue } from '../../features/venue/venueSlice';
import { auth } from '../../config/firebase';

const VenueSwitcher = () => {
  const dispatch = useDispatch();
  const venues = useSelector((state) => state.venue.userVenues);
  const loadingVenues = useSelector((state) => state.venue.loadingVenues);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const userId = auth.currentUser.uid;

  useEffect(() => {
    if (!venues) {
      dispatch(getVenuesByUserId(userId));
    }
  }, [dispatch, userId, venues]);

  const handleVenueChange = (e) => {
    const venue = venues.find((venue) => venue.id === e.target.value);
    dispatch(setSelectedVenue(venue));
  };

  const setDefaultVenue = () => {
    if (venues && venues.length > 0) {
      const home = venues.find((venue) => venue.isHome === true);

      if (home) {
        dispatch(setSelectedVenue(home));
        return home.id;
      }
    }
    return '';
  };

  return loadingVenues ? (
    <CircularProgress color="background" sx={{ mr: 2 }} />
  ) : (
    venues && (
      <Select
        name="selectedVenue"
        label="Venues"
        onChange={handleVenueChange}
        defaultValue={setDefaultVenue()}
        value={setDefaultVenue()}
        sx={{ height: 40 }}>
        {venues.map((venue) => (
          <MenuItem key={venue.id} value={venue.id}>
            {venue.name}
          </MenuItem>
        ))}
      </Select>
    )
  );
};

export default VenueSwitcher;
